/* src/components/ContactMe/contactme.css */

.contact-container {
  text-align: center;
}

/* font awesome icons */
.btn i {
  color: black;
}

.btn:hover i {
  color: gray;
}
